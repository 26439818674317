.toolbar-pagination {
  display: flex;
  flex-flow: row;

  ul {
    display: flex;
    flex-flow: row;
  }

  button {
    height: 30px;
    width: 30px;

    font-weight: 500;
    background-color: $white;
    color: $primary;

    border-color: $primary;
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
    display: flex;

    padding: 4px;

  }

  .is-selected {
    background-color: $primary;
    color: $white;
  }
}

.label-total {
  color: $grey;
  font-size: 12px;
  width: 200px !important;
}

.pagination-component {
  display: flex;
  flex-flow: row;
  align-items: center;
  width: 100%;
  padding: 0 !important;
  margin: 0 !important;

  button {
    width: 20px !important;
  }
}